<template>
  <div ref="el">
    <slot></slot>
  </div>
</template>
<script>
import 'intersection-observer'
export default {
  methods: {
    // eslint-disable-next-line no-unused-vars
    callback(entries, observer) {
      entries.forEach(entri => {
        if (entri.intersectionRatio === 1) {
          this.$emit('input', true)
        } else {
          this.$emit('input', false)
        }
      })
    }
  },
  mounted() {
    var observer = new IntersectionObserver(this.callback, {
      rootMargin: '0px',
      threshold: [0.5, 1.0]
    })
    observer.observe(this.$refs.el)
  }
}
</script>
