import moment from 'moment'
moment.locale('zh-cn')

export function isSameDay(aMoment, bMoment) {
  return aMoment.isSame(bMoment, 'day')
}
export function isSameWeek(aMoment, bMoment) {
  return aMoment.isSame(bMoment, 'week')
}

export function formatWeek(mMount) {
  return mMount.format('dddd a h:mm')
}

export function formatAM_PM(mMount) {
  return mMount.format('a h:mm')
}

export function format(mMount) {
  return mMount.format('YYYY年MM月DD日 a h:mm')
}

export function isInToday(mMount) {
  return isSameDay(mMount, moment())
}

export function getFormat(time) {
  let mMount = moment(time)
  if (isInToday(mMount)) {
    return formatAM_PM(mMount)
  } else if (isSameWeek(mMount)) {
    return formatWeek(mMount)
  }
  return format(mMount)
}

