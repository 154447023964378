export function formatNumer(n) {
    return n > 9 ? n : '0' + n
  }
  
  export function formatTime(seconds) {
    return formatNumer(Math.floor(seconds / 60)) + ':' + formatNumer(seconds % 60)
  }
  
  export function setStorage(key, value, type = 'localStorage') {
    window[type].setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value
    )
  }
  
  export function getStorage(key, type = 'localStorage') {
    var value = window[type].getItem(key)
    if (value) {
      try {
        return JSON.parse(value)
      } catch (error) {
        return value
      }
    }
    return value
  }
  
  export function copy(obj) {
    return typeof obj === 'object' ? JSON.parse(JSON.stringify(obj)) : obj
  }
  
  export function setSessionStorage(key, value) {
    setStorage(key, value, 'sessionStorage')
  }
  
  export function getSessionStorage(key) {
    return getStorage(key, 'sessionStorage')
  }
  
  export function clearSessionStorage() {
    window.sessionStorage.clear()
  }
  
  export function urlQueryString(url, params) {
    let querys = []
    for (let key in params) {
      let value = params[key]
      if (value !== undefined && value !== null) querys.push(`${key}=${value}`)
    }
    return url + (querys.length ? '?' + querys.join('&') : '')
  }
  
  /** 判断对象是否为空(有key，但是对应的val都为空，该对象为空)
   * 数组对象(里面最少有一项是有效值，非null/underfined)
   * 对于非传递的非对象，非数组，都返回true
   * @param  {} param
   */
  export function isEmpty(param) {
    if (param instanceof Object) {
      if (Array.isArray(param)) {
        var filterParam = param.filter(function(item) {
          return !!item || item === 0
        })
        return filterParam.length === 0
      } else {
        for (var key in param) {
          if (!!param[key] || param[key] === 0) {
            return false
          }
        }
        return true
      }
    }
    return false
  }
  
  export function buildParamsUrl(needParams, paramsMap) {
    if (Array.isArray(needParams)) {
      return needParams.reduce((total, cur) => {
        return total + (total.length ? '&' : '') + `${cur}=${paramsMap[cur]}`
      })
    } else if (needParams.length) {
      return `${needParams}=${paramsMap[needParams]}`
    } else {
      let keys = Object.keys(needParams)
      return buildParamsUrl(keys, paramsMap)
    }
  }
  
  export function appendUrl(url, appenUrl) {
    return url + (url.indexOf('?') === -1 ? '?' : '&') + appenUrl
  }
  
  export const groupBy = (list, fn) => {
    const groups = {}
    list.forEach(item => {
      const key = JSON.stringify(fn(item))
      groups[key] = groups[key] || []
      groups[key].push(item)
    })
  
    return groups
  }
  
  // 格式化服务器时间戳
  // 年月日时分秒必须分别按照YYYY、MM、DD、HH、mm、ss
  export const formatUnixTime = (unixTime, format = 'YYYY-MM-DD HH:mm:ss') => {
    const date = new Date(unixTime * 1000) // 后端时间戳为10位需*1000
    const YYYY = date.getFullYear()
    const month = date.getMonth() + 1 // date.getMonth()取值范围0 ~ 11
    const MM = month < 10 ? '0' + month : month
    const day = date.getDate()
    const DD = day < 10 ? '0' + day : day
    const hour = date.getHours()
    const HH = hour < 10 ? '0' + hour : hour
    const minute = date.getMinutes()
    const mm = minute < 10 ? '0' + minute : minute
    const second = date.getSeconds()
    const ss = second < 10 ? '0' + second : second
    const obj = { YYYY, MM, DD, HH, mm, ss }
  
    Object.keys(obj).forEach(key => {
      format = format.replace(new RegExp(key, 'g'), obj[key])
    })
  
    return format
  }
  
  // 获取浏览器窗口的可视区域的高度
  export const getViewPortHeight = () => {
    return document.documentElement.clientHeight || document.body.clientHeight
  }
  