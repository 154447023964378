import request from '@/controllers/request'
export default class {
  /**
   * 选择采用worker还是普通的网络请求
   * @param {*} needWorker
   */
  constructor(needWorker = true) {
    if (needWorker) {
      this.worker = new Worker('./js/worker.js')
    } else {
      this.successCb = null
      this.failCb = null
    }
  }
  postMessage(data) {
    if (this.worker) {
      this.worker.postMessage(data)
    } else {
      const action = request(data)
      action.then(data => this.successCb(data))
      if (this.failCb) action.catch(this.failCb)
    }
  }
  onmessageerror(fn) {
    this.failCb = fn
  }
  onmessage(fn) {
    if (this.worker) {
      this.worker.onmessage = evt => {
        const responseData = evt.data
        if (responseData.status === 200) {
          fn(responseData.data)
        } else if (this.failCb) {
          this.failCb(responseData)
        }
      }
    } else {
      this.successCb = fn
    }
  }
}
