<template>
  <div class="time__wrap">
    <div class="time__box">{{ content }}</div>
  </div>
</template>
<script>
export default {
  props: {
    content: String
  }
}
</script>
<style lang="scss" scoped>
.time__wrap {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  .time__box {
    padding: 0 5px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    background-color: #d1d1d1;
    color: #ffffff;
  }
}
</style>
