<template>
  <div class="card__wrap">
    <div class="card__box">
      <div class="card__head xa-cell">
        <div class="icon--msg"></div>
        消息提醒
      </div>
      <div
        class="card__body xa-line"
        :data-info="infos && infos.length > 0 ? '1' : ''"
      >
        <div class="body__title">{{ title }}</div>
        <div class="body__sub-title" v-if="subTitle" v-html="subTitle"></div>
        <div class="body__info" v-for="(info, index) in infos" :key="index">
          <div class="info__label" v-if="info.label">{{ info.label }}</div>
          <div class="info__value">{{ info.value }}</div>
        </div>
      </div>
      <div class="card__foot xa-cell">
        <div class="xa-flex">查看详情</div>
        <div class="xa-arrow"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    subTitle: String,
    infos: Array
  }
}
</script>
<style lang="scss" scoped>
.card__wrap + .card__wrap {
  overflow: hidden;
}
.card__box {
  margin: 10px 20px;
  padding: 0 13px;
  background-color: #ffffff;
  border-radius: 4px;
}
.card__body {
  padding-top: 20px;
  padding-bottom: 5px;
  &[data-info='1'] {
    padding-top: 12px;
  }
  min-height: 65px;
  .body__title {
    color: #333;
    font-size: 16px;
    line-height: 1.4;
    & + .body__sub-title {
      margin-top: 10px;
    }
    & + .body__info {
      margin-top: 8px;
    }
  }
  .body__sub-title {
    padding-bottom: 10px;
    color: #999;
    font-size: 12px;
  }
  .body__info {
    display: flex;
    padding-bottom: 5px;
    color: #999;
    font-size: 12px;
    line-height: 1.375;
    .info__label {
      min-width: 5em;
      margin-right: 0.5em;
    }
    .info__value {
      color: #666;
    }
  }
}
.card__head {
  font-size: 14px;
  color: #666;
  line-height: 35px;
  .icon--msg {
    margin-right: 5px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: rgba(32, 99, 159, 1);
    background-image: url(../../assets/msg.svg);
    background-size: 65% 65%;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.card__foot {
  font-size: 12px;
  color: #999;
  line-height: 35px;
}
.xa-line::after,
.xa-line::before {
  content: ' ';
  left: 0;
  border-color: #ccc;
}
</style>
