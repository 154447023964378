<template>
  <section class="xa-container">
    <components
      v-for="(item, index) in items"
      :key="index + item.role"
      :is="item.role"
      v-bind="item"
      @click.native="onItemClick(item)"
    />
    <section
      v-if="items.length === 0"
      class="xa-view xa-flex-center"
      style="width:100%;height:100vh;"
    >
      <div style="height:80px">
        <i
          class="iconfont icon-nodata"
          style="font-size:70px;margin-bottom:16px;line-height:0.4"
        ></i>
      </div>
      <p>暂无消息</p>
    </section>
    <VisualDom class="xa-cell" @input="handShowOldMsg" style="height: 40px;">
    </VisualDom>
  </section>
</template>
<script>
/* eslint-disable no-unused-vars */
import TimeStamp from '@/components/store-message/TimeStamp'
import MsgCard from '@/components/store-message/MsgCard'
import VisualDom from '@/components/common/VisualDom'

import androidControl, { isApp } from '@/controllers/app-android'
import miniControl, { isMini } from '@/controllers/app-wx'
import { setGlobelHeader, getTargetValueFromUrl } from '@/controllers/request'
import { getStorage, setStorage } from '@/utils/index'
import { setDocTitle } from '@/utils/pages'
import { getFormat } from '@/utils/msgMoment'
import MsgWorker from './worker'
const STORAGE_KEY = 'ENSURE-TEMPLATE_STORE-MESSAGE'
const STORAGE_SCROLLTOP_KEY = STORAGE_KEY + '/SCROLLTOP'
// 如果是在小程序里，不能使用原生worker
let worker = new MsgWorker(isMini ? false : true)

class MsgType {
  static LAST = 'LAST' // 获取最新的消息
  static AFTER = 'AFTER'
  static BEFORE = 'BEFORE'
}
const Default_psize = 20
let requestToken = ''
function setToken(token) {
  setGlobelHeader('token', token)
  requestToken = token
}
androidControl.addSubscriber('onAccessTokenCompleted', function(userInfo) {
  setToken(userInfo.token)
})
export default {
  name: 'Cache-store-message',
  components: {
    TimeStamp,
    MsgCard,
    VisualDom
  },
  data() {
    return {
      storage_key: STORAGE_KEY,
      hasInit: false,
      sleep: undefined,
      // eslint-disable-next-line vue/no-reserved-keys
      _msgs: [],
      items: [],
      isLoadingMore: false
    }
  },
  computed: {
    isRefreshing() {
      return this.$store.state.isRefreshing
    }
  },
  watch: {
    isRefreshing(value) {
      if (this._sleep === true) return
      value && this.$_toRefresh()
    },
    items() {
      this.recodeMsgs(this.$data._msgs)
    }
  },
  methods: {
    handShowOldMsg(show) {
      if (show) {
        if (this.isLoadingMore || this.hasInit === false) return
        this.isLoadingMore = true
        worker.postMessage(this.getWorkerCfg(MsgType.BEFORE))
      }
    },
    onItemClick({ href }) {
      setStorage(STORAGE_SCROLLTOP_KEY, {
        topGuid: this.$data._msgs[0].guid,
        scrollTop: document.documentElement.scrollTop || document.body.scrollTop
      })
      if (isApp && href) {
        if (href.indexOf('app://') === 0) {
          androidControl.push(href)
        } else {
          androidControl.open(href)
        }
        return
      }
      if (href) {
        const matched = this.$router.match(href)
        if (matched.matched.length > 0) {
          this.$router.push(href)
        } else if (href.indexOf('app://') === 0) {
          androidControl.push(href)
        } else {
          window.location.href = href
        }
      }
    },
    $_toRefresh() {
      worker.postMessage(this.getWorkerCfg(MsgType.LAST))
    },
    getLastGuid(type) {
      const msgs = this.$data._msgs
      if (type === MsgType.LAST || type === MsgType.AFTER) {
        return msgs[0] ? msgs[0].guid : ''
      } else if (type === MsgType.BEFORE) {
        return msgs[0] ? msgs[msgs.length - 1].guid : ''
      }
    },
    getWorkerCfg(type) {
      let lastGuid = this.getLastGuid(type)
      return {
        method: 'get',
        url: '/app/operation/message/m_list',
        headers: {
          token: requestToken
        },
        data: {
          lastGuid,
          psize: Default_psize,
          type
        }
      }
    },
    displayMsg(items, method = 'push') {
      const newItems = []
      items.forEach(item => {
        const { time, msg } = this.formateMsg(item)
        newItems.push(time)
        newItems.push(msg)
      })
      this.items[method](...newItems)
    },
    /**
     * 格式化消息
     */
    formateMsg(item) {
      const time = {
        role: 'TimeStamp',
        content: getFormat(item.time * 1000)
      }
      const msg = {
        role: 'MsgCard',
        ...item
      }
      return {
        time,
        msg
      }
    },
    recodeMsgs(msgs) {
      setStorage(this.storage_key, msgs.slice(0, Default_psize))
    },
    scrollPageTop() {
      if (this.sleep) return
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }, 0)
    },
    async appendMsgs(msgs, type, lastGuid) {
      if (msgs.length === 0) return
      if (lastGuid !== 0 && lastGuid + '' !== this.getLastGuid(type) + '')
        return // 如果当前的lastGuid与发送请求时的lastGuid不同，则抛弃返回的数据
      if (type === MsgType.LAST) {
        // 页面初始化，获取最新的消息
        if (msgs.length === Default_psize) {
          // 当拿到的消息数量达到阈值(psize),说明之前的缓存数据比较老，直接抛弃
          this.$data._msgs = []
          this.items = []
        }
        this.$data._msgs.push(...msgs)
        this.displayMsg(msgs, 'push')
        this.scrollPageTop()
      } else if (type === MsgType.AFTER) {
        // 轮询获取最新的消息 消息是guid降序
        msgs = msgs.sort((a, b) => b.guid - a.guid)
        this.$data._msgs.unshift(...msgs)
        window.console.log(this.$data._msgs)
        this.displayMsg(msgs, 'unshift')
        this.scrollPageTop()
      } else if (type === MsgType.BEFORE) {
        // 下滑获取较老的消息
        this.$data._msgs.push(...msgs)
        this.displayMsg(msgs, 'push')
      }
      window.console.log('this.$data._msgs', this.$data._msgs.length)
    },
    recoveryScrollTop() {
      const scrollInfo = getStorage(STORAGE_SCROLLTOP_KEY)
      if (
        scrollInfo &&
        this.$data._msgs.length &&
        scrollInfo.topGuid === this.$data._msgs[0].guid
      ) {
        this.$nextTick(() => {
          document.documentElement.scrollTop = document.body.scrollTop =
            scrollInfo.scrollTop
        })
      }
    }
  },
  activated() {
    setDocTitle('消息')
    this.sleep = false
    this.recoveryScrollTop()
  },
  deactivated() {
    this.sleep = true
  },
  created() {
    setDocTitle('消息')
  },
  async mounted() {
    if (isApp) {
      try {
        const userInfo = await androidControl.toGetAccessToken()
        this.storage_key = userInfo.guid + STORAGE_KEY
        setToken(userInfo.token)
      } catch (error) {
        window.console.log(error)
        window.alert('error' + JSON.stringify(error))
      }
    }
    if (isMini === false) {
      const token = getTargetValueFromUrl(window.location.href, 'token')
      setToken(token)
    }
    const storeMsgs = getStorage(this.storage_key)
    if (storeMsgs) {
      this.$data._msgs = storeMsgs
      this.displayMsg(storeMsgs)
    }
    worker.postMessage(this.getWorkerCfg(MsgType.LAST))
    setInterval(() => {
      worker.postMessage(this.getWorkerCfg(MsgType.AFTER))
    }, 10000)
    worker.onmessage(data => {
      this.hasInit = true
      const { items, type, lastGuid } = data
      this.appendMsgs(items, type, lastGuid)
      if (this.isRefreshing) this.$store.commit('triggleRefresh', false)
      if (this.isLoadingMore) this.isLoadingMore = false
    })
    worker.onmessageerror(error => {
      this.$toast.fail({
        message: error.status === 8888 ? '登录信息失效' : error.message
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.xa-container {
  min-height: 100vh;
}
</style>
